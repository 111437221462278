import RequireAuth from 'commons/auth/RequireAuth'

import DaftarIncomePage from './containers/DaftarIncomePage'
import TambahIncomePage from './containers/TambahIncomePage'
import DetailIncomePage from './containers/DetailIncomePage'
import UbahIncomePage from './containers/UbahIncomePage'

const incomeRoutes = [
  {
    path: '/income/tambah',
    element: (
      <RequireAuth permissionNeeded="CreateIncome">
        <TambahIncomePage />
      </RequireAuth>
    ),
  },
  {
    path: '/income/ubah',
    element: (
      <RequireAuth permissionNeeded="UpdateIncome">
        <UbahIncomePage />
      </RequireAuth>
    ),
  },
  {
    path: '/income/:id',
    element: <DetailIncomePage />,
  },
  {
    path: '/income',
    element: <DaftarIncomePage />,
  },
]

export default incomeRoutes
