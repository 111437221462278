import React from 'react';
import { Link } from 'react-router-dom';

import { useAuth } from 'commons/auth';
import { Button, TableRow, TableCell } from 'commons/components';

const ExpenseTable = ({ expenseItem }) => {
  const { checkPermission } = useAuth();

  return (
    <TableRow distinct={false}>
      {/* Data Binding Expense Table Element*/}
      <TableCell
		
		>{expenseItem?.datestamp}</TableCell>
      <TableCell
		
		>{expenseItem?.programName}</TableCell>
      <TableCell
		
		>{expenseItem?.coaName}</TableCell>
      <TableCell
		
		 isCurrency>{expenseItem?.amount}</TableCell>
      <TableCell>
        <div class="btn-group gap-2">
          {/* View Element Event Expense Table Element*/}
          {checkPermission("UpdateExpense") && (
            <Link to={`/expense/ubah?id=${expenseItem.id}`}>
              <Button variant="secondary">Edit</Button>
            </Link>
            
          )}
          
          <Link to={`/expense/${expenseItem.id}`}>
            <Button variant="tertiary">Detail</Button>
          </Link>
          
          
        </div>
      </TableCell>
    </TableRow>
  )
};

export default ExpenseTable;
