import React, { useContext } from 'react';
import { useNavigate } from 'react-router-dom';

import { useAuth } from 'commons/auth';
import { Button, Detail, VisualizationAttr } from 'commons/components';

import deleteIncome from '../services/deleteIncome.js';

const DetailIncome = ({ income }) => {
  const { checkPermission } = useAuth();
  const navigate = useNavigate();

  const ubah = async () => {
    navigate(
      '/income/ubah?'
      + `id=${income.id}`
      
    );
  };

  const hapus = async () => {
    await deleteIncome({
      id: income.id,
    });
    navigate('/income');
  };

  return (
    <Detail>
      {/* Data Binding Income Data */}
      <VisualizationAttr
        label="Tanggal"
        content={income?.datestamp}
		
      />
      <VisualizationAttr
        label="Deskripsi"
        content={income?.description}
		
      />
      <VisualizationAttr
        label="Jumlah"
        content={income?.amount}
		isCurrency
      />
      <VisualizationAttr
        label="Nama Program"
        content={income?.programName}
		
      />
      <VisualizationAttr
        label="Jenis Pemasukan"
        content={income?.coaName}
		
      />
      <VisualizationAttr
        label="Metode Pembayaran"
        content={income?.paymentMethod}
		
      />
      <div class="card-actions justify-end">
        {/* View Element Event Income Element*/}
          {checkPermission("DeleteIncome") && (
            <Button
              onClick={hapus}
              variant="tertiary"
            >
              Hapus
            </Button>
          )}
          {checkPermission("UpdateIncome") && (
            <Button
              onClick={ubah}
              variant="secondary"
            >
              Ubah
            </Button>
          )}
      </div>
    </Detail>
  );
};

export default DetailIncome;
