import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import DetailExpense from '../components/DetailExpense'
import getExpenseData from '../services/getExpenseData'

const DetailPengeluaranPage = (props) => {
	const [objectDetailExpense, setObjectDetailExpense] = useState()
	const { id } = useParams()

	useEffect(() => {
		const fetchData = async () => {
			const { data: expenseData } = await getExpenseData({ id })
			setObjectDetailExpense(expenseData.data)
		}
		fetchData()
  	}, [])

	return objectDetailExpense ? (
		<div className="prose max-w-screen-lg mx-auto p-6">
      		<h2>Detail Pengeluaran</h2>
			<DetailExpense {...{ objectDetailExpense }} />
		</div>
	) : <></>
}

export default DetailPengeluaranPage

