const menus = [
  {
    route: '#',
    label: 'Program',
    subMenus: [
      {
        route: '/activity',
        label: 'Aktivitas',
      },
    ]
  },
  {
    route: '#',
    label: 'Laporan Keuangan',
    subMenus: [
      {
        route: '/income',
        label: 'Pemasukan',
      },
      {
        route: '/expense',
        label: 'Pengeluaran',
      },
      {
        route: '#',
        label: 'Jurnal Keuangan',
        subMenus: [
          {
            route: '#',
            label: 'PSAK45',
            subMenus: [
              {
                route: '/laporan-arus-kas',
                label: 'Laporan Arus Kas',
              },
            ]
          },
        ]
      }
    ]
  },
  {
    route: '#',
    label: 'Donasi',
    subMenus: [
      {
        route: '/confirmation',
        label: 'Konfirmasi',
      }
    ]
  },
  {
    route: '#',
    label: 'Info Organisasi',
    subMenus: [
      {
        route: '/about',
        label: 'Tentang Kami',
      },
      {
        route: '/contact',
        label: 'Kontak',
      },
      {
        route: '/partners',
        label: 'Mitra Kami',
      },
      {
        route: '/maps',
        label: 'Lokasi',
      },
      {
        route: '/bank-account',
        label: 'Rekening Donasi',
      }
    ]
  },
]

export const settingsMenu = [
  {
    route: '#',
    label: 'Pengaturan',
    subMenus: [
      {
        route: '/settings/appearance',
        label: 'Pengaturan Tampilan',
      },
      {
        route: '/settings/role',
        label: 'Pengaturan Role',
      },
      {
        route: '/settings/user',
        label: 'Pengaturan User',
      },
    ]
  },
]

export default menus
