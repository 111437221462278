import React from 'react';
import { Link } from 'react-router-dom';

import { useAuth } from 'commons/auth';
import { Button, TableRow, TableCell } from 'commons/components';

const IncomeTable = ({ incomeItem }) => {
  const { checkPermission } = useAuth();

  return (
    <TableRow distinct={false}>
      {/* Data Binding Income Table Element*/}
      <TableCell
		
		>{incomeItem?.datestamp}</TableCell>
      <TableCell
		
		>{incomeItem?.programName}</TableCell>
      <TableCell
		
		>{incomeItem?.paymentMethod}</TableCell>
      <TableCell
		
		 isCurrency>{incomeItem?.amount}</TableCell>
      <TableCell>
        <div class="btn-group gap-2">
          {/* View Element Event Income Table Element*/}
          <Link to={`/income/${incomeItem.id}`}>
            <Button variant="tertiary">Detail</Button>
          </Link>
          
          
          {checkPermission("UpdateIncome") && (
            <Link to={`/income/ubah?id=${incomeItem.id}`}>
              <Button variant="secondary">Edit</Button>
            </Link>
            
          )}
          
        </div>
      </TableCell>
    </TableRow>
  )
};

export default IncomeTable;
