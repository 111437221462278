import React from 'react'
import { Controller, useForm } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'
import toast from 'react-hot-toast'
import {
  Button,
  Form,
  SelectionField,
  MultiSelectionField,
  InputField,
  
  
} from 'commons/components'

import cleanFormData from 'commons/utils/cleanFormData'

import updateUser from '../services/updateUser'

const FormUbahUser = ({ user }) => {
  const { control, handleSubmit } = useForm({ defaultValues: user })


  const navigate = useNavigate()

  const kirim = (data) => {
    const cleanData = cleanFormData(data)
    updateUser({
      ...cleanData,
      
    })
    .then(({ data: { data } }) => {
      navigate(`/settings/user/${user.id}`)
    })
    .catch((error) => {
      toast.error(error.response?.data?.data?.message || error || 'Terjadi kesalahan pada sistem. Harap coba lagi!');
    });
  }

  return (
    <Form 
	  title="Ubah User" 
	  onSubmit={handleSubmit(kirim)}
	  >

	  <Controller
        name="id"
        control={control}
		rules={{ required: "Harap masukkan id user" }} 
        render={({ field, fieldState }) => (
          <InputField
            label="Id User"
            placeholder="Masukkan id user"
			type="number"
			disabled            defaultValue={user.id}            fieldState={fieldState}
			{...field}
          />
        )}
      />
	  <Controller
        name="name"
        control={control}
		rules={{ required: "Harap masukkan name" }} 
        render={({ field, fieldState }) => (
          <InputField
            label="Name"
            placeholder="Masukkan name"
            defaultValue={user.name}            fieldState={fieldState}
			{...field}
          />
        )}
      />
	  <Controller
        name="email"
        control={control}
		rules={{ required: "Harap masukkan email" }} 
        render={({ field, fieldState }) => (
          <InputField
            label="Email"
            placeholder="Masukkan email"
            defaultValue={user.email}            fieldState={fieldState}
			{...field}
          />
        )}
      />
	  <Controller
        name="allowedPermissions"
        control={control}
		rules={{ required: "Harap masukkan allowed permissions" }} 
        render={({ field, fieldState }) => (
          <InputField
            label="Allowed Permissions"
            placeholder="Masukkan allowed permissions"
            defaultValue={user.allowedPermissions}            fieldState={fieldState}
			{...field}
          />
        )}
      />
		<div className="card-actions justify-end">
			<Button type="submit" variant="primary">Kirim</Button>
		</div>
    </Form>
  )
}

export default FormUbahUser
