import React, { useContext } from 'react';
import { useNavigate } from 'react-router-dom';

import { useAuth } from 'commons/auth';
import { Button, Detail, VisualizationAttr } from 'commons/components';


const KonfirmasiDonasi = ({ confirmationData }) => {
  const { checkPermission } = useAuth();
  const navigate = useNavigate();

  return (
    <Detail>
      {/* Data Binding Donasi Data */}
      <VisualizationAttr
        label="Donasi Berhasil! Berikut Kode Donasi Anda"
        content={confirmationData?.id}
		
      />
      <VisualizationAttr
        label="Donatur"
        content={confirmationData?.name}
		
      />
      <VisualizationAttr
        label="Jumlah Donasi"
        content={confirmationData?.amount}
		
      />
      <VisualizationAttr
        label="Tanggal Bayar"
        content={confirmationData?.date}
		
      />
      <VisualizationAttr
        label="Bukti Transfer"
        content={confirmationData?.proofOfTransfer}
		
      />
      <div class="card-actions justify-end">
        {/* View Element Event Donasi Element*/}
      </div>
    </Detail>
  );
};

export default KonfirmasiDonasi;
