import React from 'react'
import { Controller, useForm } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'
import toast from 'react-hot-toast'
import {
  Button,
  Form,
  SelectionField,
  MultiSelectionField,
  InputField,
  VisualizationAttr,
  
} from 'commons/components'

import cleanFormData from 'commons/utils/cleanFormData'

import updateStatusOfflineDonation from '../services/updateStatusOfflineDonation'

const FormUpdateStatusKonfirmasiDonasiOffline = ({ confirmOfflineDonation, statuses }) => {
  const { control, handleSubmit } = useForm({ defaultValues: confirmOfflineDonation })


  const navigate = useNavigate()

  const kirim = (data) => {
    const cleanData = cleanFormData(data)
    updateStatusOfflineDonation({
      ...cleanData,
      
    })
    .then(({ data: { data } }) => {
      navigate(`/confirmation/detail/${confirmOfflineDonation.id}`)
    })
    .catch((error) => {
      toast.error(error.response?.data?.data?.message || error || 'Terjadi kesalahan pada sistem. Harap coba lagi!');
    });
  }

  return (
    <Form 
	  title="Update Status Konfirmasi Donasi Offline" 
	  onSubmit={handleSubmit(kirim)}
	  >
      <VisualizationAttr
        label="Nama"
        content={confirmOfflineDonation?.name}
		
      />
      <VisualizationAttr
        label="Deskripsi"
        content={confirmOfflineDonation?.description}
		
      />
      <VisualizationAttr
        label="Tanggal"
        content={confirmOfflineDonation?.date}
		
      />
      <VisualizationAttr
        label="Metode Pembayaran"
        content={confirmOfflineDonation?.paymentMethod}
		
      />
      <VisualizationAttr
        label="Jumlah"
        content={confirmOfflineDonation?.amount}
		
      />

	  <Controller
        name="status"
        control={control}
		rules={{ required: "Harap pilih status" }} 
        render={({ field, fieldState }) => (
		<SelectionField
            label="Status"
            options={statuses}
            placeholder="Masukkan status"
			fieldState={fieldState}
			defaultValue={confirmOfflineDonation.status}
            {...field}
          />
        )}
      />
		<div className="card-actions justify-end">
			<Button type="submit" variant="primary">Kirim</Button>
		</div>
    </Form>
  )
}

export default FormUpdateStatusKonfirmasiDonasiOffline
