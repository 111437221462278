import React, { useEffect, useState } from 'react'
import { useSearchParams } from 'react-router-dom'

import getProgramData from '../services/getProgramData'

import FormUbahDataProgram from '../components/FormUbahDataProgram'

const UbahProgramPage = props => {
  const [searchParams] = useSearchParams()
  const id = searchParams.get('id')

  const [programData, setProgramData] = useState()

  useEffect(() => {
    const fetch = async () => {
	  const { data: programDataResponse } = await getProgramData({ id })

	  setProgramData(programDataResponse.data)
    }
    fetch()
  }, [])

  return programData ? (
	<FormUbahDataProgram
		{...{ programData }}
		{...props}
	/> 
  ) : <></>
}

export default UbahProgramPage

