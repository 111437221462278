import React from 'react'
import { Controller, useForm } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'
import toast from 'react-hot-toast'
import {
  Button,
  Form,
  SelectionField,
  MultiSelectionField,
  InputField,
  
  
} from 'commons/components'

import cleanFormData from 'commons/utils/cleanFormData'

import updateIncome from '../services/updateIncome'

const FormUbahPemasukan = ({ income, programs, chartOfAccounts }) => {
  const { control, handleSubmit } = useForm({ defaultValues: income })


  const navigate = useNavigate()

  const kirim = (data) => {
    const cleanData = cleanFormData(data)
    updateIncome({
      ...cleanData,
      
    })
    .then(({ data: { data } }) => {
      navigate(`/income/${income.id}`)
    })
    .catch((error) => {
      toast.error(error.response?.data?.data?.message || error || 'Terjadi kesalahan pada sistem. Harap coba lagi!');
    });
  }

  return (
    <Form 
	  title="Ubah Pemasukan" 
	  onSubmit={handleSubmit(kirim)}
	  >

	  <Controller
        name="id"
        control={control}
		rules={{ required: "Harap masukkan id income" }} 
        render={({ field, fieldState }) => (
          <InputField
            label="Id Income"
            placeholder="Masukkan id income"
			type="number"
			disabled            defaultValue={income.id}            fieldState={fieldState}
			{...field}
          />
        )}
      />
	  <Controller
        name="datestamp"
        control={control}
		rules={{ required: "Harap masukkan tanggal" }} 
        render={({ field, fieldState }) => (
          <InputField
            label="Tanggal"
            placeholder="Masukkan tanggal"
			type="date"
            defaultValue={income.datestamp}            fieldState={fieldState}
			{...field}
          />
        )}
      />
	  <Controller
        name="description"
        control={control}
		rules={{ required: "Harap masukkan deskripsi" }} 
        render={({ field, fieldState }) => (
          <InputField
            label="Deskripsi"
            placeholder="Masukkan deskripsi"
            defaultValue={income.description}            fieldState={fieldState}
			{...field}
          />
        )}
      />
	  <Controller
        name="amount"
        control={control}
		rules={{ required: "Harap masukkan jumlah" }} 
        render={({ field, fieldState }) => (
          <InputField
            label="Jumlah"
            placeholder="Masukkan jumlah"
			type="number"
            defaultValue={income.amount}            fieldState={fieldState}
			{...field}
          />
        )}
      />
	  <Controller
        name="paymentMethod"
        control={control}
		rules={{ required: "Harap masukkan metode pembayaran" }} 
        render={({ field, fieldState }) => (
          <InputField
            label="Metode Pembayaran"
            placeholder="Masukkan metode pembayaran"
            defaultValue={income.paymentMethod}            fieldState={fieldState}
			{...field}
          />
        )}
      />
	  <Controller
        name="idProgram"
        control={control}
		rules={{ required: "Harap pilih nama program terkait" }} 
        render={({ field, fieldState }) => (
		<SelectionField
            label="Nama Program Terkait"
            options={programs}
            placeholder="Masukkan nama program terkait"
			fieldState={fieldState}
			defaultValue={income.idProgram}
            {...field}
          />
        )}
      />
	  <Controller
        name="idCoa"
        control={control}
		rules={{ required: "Harap pilih kode akun" }} 
        render={({ field, fieldState }) => (
		<SelectionField
            label="Kode Akun"
            options={chartOfAccounts}
            placeholder="Masukkan kode akun"
			fieldState={fieldState}
			defaultValue={income.idCoa}
            {...field}
          />
        )}
      />
		<div className="card-actions justify-end">
			<Button type="submit" variant="primary">Kirim</Button>
		</div>
    </Form>
  )
}

export default FormUbahPemasukan
