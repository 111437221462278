import React from 'react'

import FormTambahkanRole from '../components/FormTambahkanRole'

const TambahRolePage = props => {

  return  (
	<FormTambahkanRole
		{...props}
	/> 
  ) 
}

export default TambahRolePage

