import React, { useContext } from 'react';
import { useNavigate } from 'react-router-dom';

import { useAuth } from 'commons/auth';
import { Button, Detail, VisualizationAttr } from 'commons/components';

import deleteRole from '../services/deleteRole.js';

const DetailRole = ({ role }) => {
  const { checkPermission } = useAuth();
  const navigate = useNavigate();

  const hapus = async () => {
    await deleteRole({
      id: role.id,
    });
    navigate('/settings/role');
  };

  const ubah = async () => {
    navigate(
      '/settings/role/ubah?'
      + `id=${role.id}`
      
    );
  };

  return (
    <Detail>
      {/* Data Binding Role Data */}
      <VisualizationAttr
        label="Name"
        content={role?.name}
		
      />
      <VisualizationAttr
        label="Allowed Permissions"
        content={role?.allowedPermissions}
		
      />
      <div class="card-actions justify-end">
        {/* View Element Event Role Element*/}
          {checkPermission("administrator") && (
            <Button
              onClick={hapus}
              variant="tertiary"
            >
              Hapus
            </Button>
          )}
          {checkPermission("administrator") && (
            <Button
              onClick={ubah}
              variant="secondary"
            >
              Ubah
            </Button>
          )}
      </div>
    </Detail>
  );
};

export default DetailRole;
