import React from 'react'
import { Controller, useForm } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'
import toast from 'react-hot-toast'
import {
  Button,
  Form,
  SelectionField,
  MultiSelectionField,
  InputField,
  
  
} from 'commons/components'

import cleanFormData from 'commons/utils/cleanFormData'

import changeroleUser from '../services/changeroleUser'

const FormUbahRoleUser = ({ user, roles }) => {
  const { control, handleSubmit } = useForm({ defaultValues: user })


  const navigate = useNavigate()

  const kirim = (data) => {
    const cleanData = cleanFormData(data)
    changeroleUser({
      ...cleanData,
      
    })
    .then(({ data: { data } }) => {
      navigate(`/settings/user/${user.id}`)
    })
    .catch((error) => {
      toast.error(error.response?.data?.data?.message || error || 'Terjadi kesalahan pada sistem. Harap coba lagi!');
    });
  }

  return (
    <Form 
	  title="Ubah Role User" 
	  onSubmit={handleSubmit(kirim)}
	  >

	  <Controller
        name="id"
        control={control}
		rules={{ required: "Harap masukkan id user" }} 
        render={({ field, fieldState }) => (
          <InputField
            label="Id User"
            placeholder="Masukkan id user"
			type="number"
			disabled            defaultValue={user.id}            fieldState={fieldState}
			{...field}
          />
        )}
      />
	  <Controller
        name="roleIds"
        control={control}
		rules={{ required: "Harap pilih roles" }} 
        render={({ field, fieldState }) => (
		<MultiSelectionField
            label="Roles"
            options={roles}
            placeholder="Masukkan roles"
			fieldState={fieldState}
			defaultValue={user.roleIds}
            {...field}
          />
        )}
      />
		<div className="card-actions justify-end">
			<Button type="submit" variant="primary">kirim</Button>
		</div>
    </Form>
  )
}

export default FormUbahRoleUser
