import React from 'react'
import { Controller, useForm } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'
import toast from 'react-hot-toast'
import {
  Button,
  Form,
  SelectionField,
  MultiSelectionField,
  InputField,
  
  
} from 'commons/components'

import cleanFormData from 'commons/utils/cleanFormData'

import updateRole from '../services/updateRole'

const FormUbahRole = ({ role }) => {
  const { control, handleSubmit } = useForm({ defaultValues: role })


  const navigate = useNavigate()

  const kirim = (data) => {
    const cleanData = cleanFormData(data)
    updateRole({
      ...cleanData,
      
    })
    .then(({ data: { data } }) => {
      navigate(`/settings/role/${role.id}`)
    })
    .catch((error) => {
      toast.error(error.response?.data?.data?.message || error || 'Terjadi kesalahan pada sistem. Harap coba lagi!');
    });
  }

  return (
    <Form 
	  title="Ubah Role" 
	  onSubmit={handleSubmit(kirim)}
	  >

	  <Controller
        name="id"
        control={control}
		rules={{ required: "Harap masukkan id role" }} 
        render={({ field, fieldState }) => (
          <InputField
            label="Id Role"
            placeholder="Masukkan id role"
			type="number"
			disabled            defaultValue={role.id}            fieldState={fieldState}
			{...field}
          />
        )}
      />
	  <Controller
        name="name"
        control={control}
		rules={{ required: "Harap masukkan nama role" }} 
        render={({ field, fieldState }) => (
          <InputField
            label="Nama Role"
            placeholder="Masukkan nama role"
            defaultValue={role.name}            fieldState={fieldState}
			{...field}
          />
        )}
      />
	  <Controller
        name="allowedPermissions"
        control={control}
		rules={{ required: "Harap masukkan allowed permissions" }} 
        render={({ field, fieldState }) => (
          <InputField
            label="Allowed Permissions"
            placeholder="Masukkan allowed permissions"
            defaultValue={role.allowedPermissions}            fieldState={fieldState}
			{...field}
          />
        )}
      />
		<div className="card-actions justify-end">
			<Button type="submit" variant="primary">Kirim</Button>
		</div>
    </Form>
  )
}

export default FormUbahRole
