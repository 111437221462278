import React from 'react'
import { Controller, useForm } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'
import toast from 'react-hot-toast'
import {
  Button,
  Form,
  SelectionField,
  MultiSelectionField,
  InputField,
  
  
} from 'commons/components'

import cleanFormData from 'commons/utils/cleanFormData'

import saveUser from '../services/saveUser'

const FormTambahkanUser = ({ roles }) => {
  const { control, handleSubmit } = useForm()


  const navigate = useNavigate()

  const kirim = (data) => {
    const cleanData = cleanFormData(data)
    saveUser({
      ...cleanData,
      
    })
    .then(({ data: { data } }) => {
      navigate(`/settings/user`)
    })
    .catch((error) => {
      toast.error(error.response?.data?.data?.message || error || 'Terjadi kesalahan pada sistem. Harap coba lagi!');
    });
  }

  return (
    <Form 
	  title="Tambahkan User" 
	  onSubmit={handleSubmit(kirim)}
	  >

	  <Controller
        name="name"
        control={control}
		rules={{ required: "Harap masukkan name" }} 
        render={({ field, fieldState }) => (
          <InputField
            label="Name"
            placeholder="Masukkan name"
            fieldState={fieldState}
			{...field}
          />
        )}
      />
	  <Controller
        name="email"
        control={control}
		rules={{ required: "Harap masukkan email" }} 
        render={({ field, fieldState }) => (
          <InputField
            label="Email"
            placeholder="Masukkan email"
            fieldState={fieldState}
			{...field}
          />
        )}
      />
	  <Controller
        name="allowedPermissions"
        control={control}
		rules={{ required: "Harap masukkan allowed permissions" }} 
        render={({ field, fieldState }) => (
          <InputField
            label="Allowed Permissions"
            placeholder="Masukkan allowed permissions"
            fieldState={fieldState}
			{...field}
          />
        )}
      />
	  <Controller
        name="password"
        control={control}
		rules={{ required: "Harap masukkan password" }} 
        render={({ field, fieldState }) => (
          <InputField
            label="Password"
            placeholder="Masukkan password"
			type="password"
            fieldState={fieldState}
			{...field}
          />
        )}
      />
	  <Controller
        name="roleIds"
        control={control}
		rules={{ required: "Harap pilih roles" }} 
        render={({ field, fieldState }) => (
		<MultiSelectionField
            label="Roles"
            options={roles}
            placeholder="Masukkan roles"
			fieldState={fieldState}
            {...field}
          />
        )}
      />
		<div className="card-actions justify-end">
			<Button type="submit" variant="primary">Kirim</Button>
		</div>
    </Form>
  )
}

export default FormTambahkanUser
