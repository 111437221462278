import React, { useEffect, useState, useContext } from 'react'
import { Link } from 'react-router-dom'

import {useAuth} from 'commons/auth';
import { Button, Table, TableRow, TableCell, TableBody, TableHead, List }  from 'commons/components'

import ProgramCard from '../components/ProgramCard'
import getProgramListElement from '../services/getProgramListElement'

const DaftarProgramPage = props => {
	const {checkPermission} = useAuth();
	const [programs, setPrograms] = useState()

  useEffect(() => {
		const fetchData = async () => {
			const { data: programListElement } = await getProgramListElement()
			setPrograms(programListElement.data)
		}
		fetchData()
  	}, [])

  return (
    <div className="mx-auto w-full max-w-screen-xl prose p-6 flex flex-col prose">
      <div className="w-full flex justify-center sm:justify-between items-center mb-4">
      <h2 className="m-0">Daftar Program</h2>
	  <div className="not-prose flex items-center gap-4">
        {programs && `Terdapat ${programs.length} Program`}
        {checkPermission("CreateProgram") && (
          <Link to="/activity/tambah">
          	<Button>Tambah Program</Button>
          </Link>
        )}
      </div>
	</div>
	<div className="not-prose">
		<GridView {...{ programs }} />
    </div>
	</div>
  )
}

const GridView = ({ programs }) => {
  return (
    <List column="4">
      {programs &&
        programs.map(programItem => (
          <ProgramCard 
			key={programItem.id}			 
			programItem={programItem}
		  />
        ))}
    </List>
  )
}

export default DaftarProgramPage

