import React, { useEffect, useState, useContext } from 'react'
import { Link } from 'react-router-dom'

import {useAuth} from 'commons/auth';
import { Button, Table, TableRow, TableCell, TableBody, TableHead, List }  from 'commons/components'

import DonasiTable from '../components/DonasiTable'
import getKonfirmasiDonasiOfflineListElement from '../services/getKonfirmasiDonasiOfflineListElement'

const ListKonfirmasiDonasiOfflinePage = props => {
	const {checkPermission} = useAuth();
	const [jsonAllDonasiOffline, setJsonAllDonasiOffline] = useState()

  useEffect(() => {
		const fetchData = async () => {
			const { data: konfirmasiDonasiOfflineListElement } = await getKonfirmasiDonasiOfflineListElement()
			setJsonAllDonasiOffline(konfirmasiDonasiOfflineListElement.data)
		}
		fetchData()
  	}, [])

  return (
    <div className="mx-auto w-full max-w-screen-xl prose p-6 flex flex-col prose">
      <div className="w-full flex justify-center sm:justify-between items-center mb-4">
      <h2 className="m-0">List Konfirmasi Donasi Offline</h2>
	  <div className="not-prose flex items-center gap-4">
        
        <Link to="/confirmation/confirm">
        	<Button>Konfirmasi Donasi</Button>
        </Link>
      </div>
	</div>
	<div className="not-prose">
		<TableView {...{ jsonAllDonasiOffline }} />
    </div>
	</div>
  )
}

const TableView = ({ jsonAllDonasiOffline }) => {
  return (
    <div className="card bg-base-100">
		<div className="card-body">
      <Table>
		<TableHead>
          <TableRow>
            <TableCell id="nama">Nama</TableCell>
            <TableCell id="email">Email</TableCell>
            <TableCell id="no.Telp">No. Telp</TableCell>
            <TableCell id="metodePembayaran">Metode Pembayaran</TableCell>
            <TableCell id="jumlah">Jumlah</TableCell>
            <TableCell id="status">Status</TableCell>
            <TableCell></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {jsonAllDonasiOffline &&
            jsonAllDonasiOffline.map(donasiItem => (
              <DonasiTable 
                key={donasiItem.id}
                donasiItem={donasiItem}
              />
            ))}
        </TableBody>
      </Table>
		</div>
    </div>
  )
}

export default ListKonfirmasiDonasiOfflinePage

