import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import KonfirmasiDonasi from '../components/KonfirmasiDonasi'
import getDonationData from '../services/getDonationData'

const BerhasilKonfirmasiPage = (props) => {
	const [confirmationData, setConfirmationData] = useState()
	const { id } = useParams()

	useEffect(() => {
		const fetchData = async () => {
			const { data: donationData } = await getDonationData({ id })
			setConfirmationData(donationData.data)
		}
		fetchData()
  	}, [])

	return confirmationData ? (
		<div className="prose max-w-screen-lg mx-auto p-6">
      		<h2>Berhasil Konfirmasi</h2>
			<KonfirmasiDonasi {...{ confirmationData }} />
		</div>
	) : <></>
}

export default BerhasilKonfirmasiPage

