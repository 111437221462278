import React from 'react'

import FormTambahkanProgram from '../components/FormTambahkanProgram'

const TambahProgramPage = props => {

  return  (
	<FormTambahkanProgram
		{...props}
	/> 
  ) 
}

export default TambahProgramPage

