import React, { useEffect, useState } from 'react'

import getPrograms from '../services/getPrograms'
import getChartOfAccounts from '../services/getChartOfAccounts'

import FormTambahkanPengeluaran from '../components/FormTambahkanPengeluaran'

const TambahPengeluaranPage = props => {
  const [programs, setPrograms] = useState()
  const [chartOfAccounts, setChartOfAccounts] = useState()

  useEffect(() => {
    const fetch = async () => {
	  const { data: programsResponse } = await getPrograms()
	  const { data: chartOfAccountsResponse } = await getChartOfAccounts()

	  setPrograms(programsResponse.data)
	  setChartOfAccounts(chartOfAccountsResponse.data)
    }
    fetch()
  }, [])

  return programs && chartOfAccounts ? (
	<FormTambahkanPengeluaran
		{...{ programs, chartOfAccounts }}
		{...props}
	/> 
  ) : <></>
}

export default TambahPengeluaranPage

