import React from 'react'
import { Controller, useForm } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'
import toast from 'react-hot-toast'
import {
  Button,
  Form,
  SelectionField,
  MultiSelectionField,
  InputField,
  
  
} from 'commons/components'

import cleanFormData from 'commons/utils/cleanFormData'

import saveProgram from '../services/saveProgram'

const FormTambahkanProgram = ({  }) => {
  const { control, handleSubmit } = useForm()


  const navigate = useNavigate()

  const kirim = (data) => {
    const cleanData = cleanFormData(data)
    saveProgram({
      ...cleanData,
      
    })
    .then(({ data: { data } }) => {
      navigate(`/activity`)
    })
    .catch((error) => {
      toast.error(error.response?.data?.data?.message || error || 'Terjadi kesalahan pada sistem. Harap coba lagi!');
    });
  }

  return (
    <Form 
	  title="Tambahkan Program" 
	  onSubmit={handleSubmit(kirim)}
	  >

	  <Controller
        name="name"
        control={control}
		rules={{ required: "Harap masukkan nama program" }} 
        render={({ field, fieldState }) => (
          <InputField
            label="Nama Program"
            placeholder="Masukkan nama program"
            fieldState={fieldState}
			{...field}
          />
        )}
      />
	  <Controller
        name="description"
        control={control}
		rules={{ required: "Harap masukkan deskripsi" }} 
        render={({ field, fieldState }) => (
          <InputField
            label="Deskripsi"
            placeholder="Masukkan deskripsi"
            fieldState={fieldState}
			{...field}
          />
        )}
      />
	  <Controller
        name="target"
        control={control}
		rules={{ required: "Harap masukkan target" }} 
        render={({ field, fieldState }) => (
          <InputField
            label="Target"
            placeholder="Masukkan target"
            fieldState={fieldState}
			{...field}
          />
        )}
      />
	  <Controller
        name="partner"
        control={control}
		rules={{ required: "Harap masukkan partner" }} 
        render={({ field, fieldState }) => (
          <InputField
            label="Partner"
            placeholder="Masukkan partner"
            fieldState={fieldState}
			{...field}
          />
        )}
      />
	  <Controller
        name="executionDate"
        control={control}
		rules={{ required: "Harap masukkan tanggal pelaksanaan" }} 
        render={({ field, fieldState }) => (
          <InputField
            label="Tanggal Pelaksanaan"
            placeholder="Masukkan tanggal pelaksanaan"
			type="date"
            fieldState={fieldState}
			{...field}
          />
        )}
      />
	  <Controller
        name="logoUrl"
        control={control}
		rules={{ required: "Harap masukkan url gambar program" }} 
        render={({ field, fieldState }) => (
          <InputField
            label="URL Gambar Program"
            placeholder="Masukkan url gambar program"
            fieldState={fieldState}
			{...field}
          />
        )}
      />
		<div className="card-actions justify-end">
			<Button type="submit" variant="primary">Kirim</Button>
		</div>
    </Form>
  )
}

export default FormTambahkanProgram
