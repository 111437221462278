import React from 'react'
import { Controller, useForm } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'
import toast from 'react-hot-toast'
import {
  Button,
  Form,
  SelectionField,
  MultiSelectionField,
  InputField,
  
  
} from 'commons/components'

import cleanFormData from 'commons/utils/cleanFormData'

import saveIncome from '../services/saveIncome'

const FormTambahkanPemasukan = ({ programs, chartOfAccounts }) => {
  const { control, handleSubmit } = useForm()


  const navigate = useNavigate()

  const kirim = (data) => {
    const cleanData = cleanFormData(data)
    saveIncome({
      ...cleanData,
      
    })
    .then(({ data: { data } }) => {
      navigate(`/income`)
    })
    .catch((error) => {
      toast.error(error.response?.data?.data?.message || error || 'Terjadi kesalahan pada sistem. Harap coba lagi!');
    });
  }

  return (
    <Form 
	  title="Tambahkan Pemasukan" 
	  onSubmit={handleSubmit(kirim)}
	  >

	  <Controller
        name="datestamp"
        control={control}
		rules={{ required: "Harap masukkan tanggal" }} 
        render={({ field, fieldState }) => (
          <InputField
            label="Tanggal"
            placeholder="Masukkan tanggal"
			type="date"
            fieldState={fieldState}
			{...field}
          />
        )}
      />
	  <Controller
        name="description"
        control={control}
		rules={{ required: "Harap masukkan deskripsi" }} 
        render={({ field, fieldState }) => (
          <InputField
            label="Deskripsi"
            placeholder="Masukkan deskripsi"
            fieldState={fieldState}
			{...field}
          />
        )}
      />
	  <Controller
        name="amount"
        control={control}
		rules={{ required: "Harap masukkan jumlah" }} 
        render={({ field, fieldState }) => (
          <InputField
            label="Jumlah"
            placeholder="Masukkan jumlah"
			type="number"
            fieldState={fieldState}
			{...field}
          />
        )}
      />
	  <Controller
        name="paymentMethod"
        control={control}
		rules={{ required: "Harap pilih metode pembayaran" }} 
        render={({ field, fieldState }) => (
		<SelectionField
            label="Metode Pembayaran"
            options={[{ id: "Transfer Bank", name: "Transfer Bank" },
            { id: "Gopay", name: "Gopay" },
            { id: "ShopeePay", name: "ShopeePay" },
            { id: "Ovo", name: "Ovo" }]}
            placeholder="Masukkan metode pembayaran"
			fieldState={fieldState}
            {...field}
          />
        )}
      />
	  <Controller
        name="idProgram"
        control={control}
		rules={{ required: "Harap pilih nama program terkait" }} 
        render={({ field, fieldState }) => (
		<SelectionField
            label="Nama Program Terkait"
            options={programs}
            placeholder="Masukkan nama program terkait"
			fieldState={fieldState}
            {...field}
          />
        )}
      />
	  <Controller
        name="idCoa"
        control={control}
		rules={{ required: "Harap pilih kode akun" }} 
        render={({ field, fieldState }) => (
		<SelectionField
            label="Kode Akun"
            options={chartOfAccounts}
            placeholder="Masukkan kode akun"
			fieldState={fieldState}
            {...field}
          />
        )}
      />
		<div className="card-actions justify-end">
			<Button type="submit" variant="primary">Kirim</Button>
		</div>
    </Form>
  )
}

export default FormTambahkanPemasukan
