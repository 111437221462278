import React from 'react'

const List = ({ className, children, column = 1 }) => {
  return (
    <ul
      className={`grid grid-cols-3 w-full gap-6 ${className}`}
      style={{ gridTemplateColumns: `repeat(${column}, minmax(0, 1fr))` }}
      column={column}
    >
      {children}
    </ul>
  )
}

export default List
