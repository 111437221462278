import React, { useEffect, useState } from 'react'
import { useSearchParams } from 'react-router-dom'

import getIncome from '../services/getIncome'
import getPrograms from '../services/getPrograms'
import getChartOfAccounts from '../services/getChartOfAccounts'

import FormUbahPemasukan from '../components/FormUbahPemasukan'

const UbahIncomePage = props => {
  const [searchParams] = useSearchParams()
  const id = searchParams.get('id')

  const [income, setIncome] = useState()
  const [programs, setPrograms] = useState()
  const [chartOfAccounts, setChartOfAccounts] = useState()

  useEffect(() => {
    const fetch = async () => {
	  const { data: incomeResponse } = await getIncome({ id })
	  const { data: programsResponse } = await getPrograms()
	  const { data: chartOfAccountsResponse } = await getChartOfAccounts()

	  setIncome(incomeResponse.data)
	  setPrograms(programsResponse.data)
	  setChartOfAccounts(chartOfAccountsResponse.data)
    }
    fetch()
  }, [])

  return income && programs && chartOfAccounts ? (
	<FormUbahPemasukan
		{...{ income, programs, chartOfAccounts }}
		{...props}
	/> 
  ) : <></>
}

export default UbahIncomePage

